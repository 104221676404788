import React from 'react'

export const Privacy = () => {
  return (
    <section className='terms_Privacy_Page'>
        <div className="portfolio_body">
          <div className="container custom-container">
            <div className="main">
              <div className="privacy_area main_topic_margin">
                <div className="privacy_area_top">
                  <h1>Tribel Privacy Policy</h1>

                  <div className="row">
                    <div className="col-md-12 color-3">
                      Updated at 2020-11-13
                    </div>
                  </div>
                </div>
                <div className="privacy_area_body_area">
                  <div className="privacy_area_body">
                    <div className="plainText">
                      <p>
                        Tribel is committed to protecting your privacy. This
                        Privacy Policy explains how your personal information is
                        collected, used, and disclosed by Tribel.
                      </p>
                      <p>
                        This Privacy Policy applies to our website, and its
                        associated subdomains (collectively, our “Service”)
                        alongside our application, Tribel. By accessing or using
                        our Service, you signify that you have read, understood,
                        and agree to our collection, storage, use, and
                        disclosure of your personal information as described in
                        this Privacy Policy and our Terms of Service.
                      </p>

                      <div className="row mt-40">
                        <div className="col-md-4">
                          <ul className="privacy-links left-links">
                            <li>
                              <a href="#Definitions-and-key-terms">
                                Definitions and key terms{" "}
                              </a>
                            </li>
                            <li>
                              <a href="#What-Information-Do-We-Collect">
                                What Information Do We Collect?
                              </a>
                            </li>
                            <li>
                              <a href="#How-Do-We-Use-The-Information-We-Collect">
                                How Do We Use The Information We Collect?
                              </a>
                            </li>
                            <li>
                              <a href="#When-does-Tribel-use-end-user-information-from-third-parties-and-public-Network">
                                When does Tribel use end user information from
                                third parties and public Network?
                              </a>
                            </li>
                            <li>
                              <a href="#Where-and-when-is-information-collected-from-customers-and-end-users">
                                Where and when is information collected from
                                customers and end users?
                              </a>
                            </li>
                            <li>
                              <a href="#Can-I-update-or-correct-my-information">
                                Can I update or correct my information?
                              </a>
                            </li>
                            <li>
                              <a href="#Sale-of-Business">Sale of Business</a>
                            </li>
                            <li>
                              <a href="#Affiliates">Affiliates</a>
                            </li>
                            <li>
                              <a href="#Governing-Law">Governing-Law</a>
                            </li>
                            <li>
                              <a href="#Your-Consent">Your Consent</a>
                            </li>
                            <li>
                              <a href="#Links-to-Other-Websites">
                                Links to Other Websites
                              </a>
                            </li>
                            <li>
                              <a href="#Advertising">Advertising</a>
                            </li>
                            <li>
                              <a href="#Cookies">Cookies</a>
                            </li>
                            <li>
                              <a href="#Kids-Privacy">Kid's Privacy</a>
                            </li>
                            <li>
                              <a href="#Third-Party-Services">
                                Third-Party Services
                              </a>
                            </li>
                            <li>
                              <a href="#Information-about-General-Data-Protection-Regulation-GDPR">
                                Information about General Data Protection
                                Regulation (GDPR)
                              </a>
                            </li>
                            <li>
                              <a href="#Individual-Data-Subjects-Rights-Data-Access-Portability-and-Deletion">
                                Individual Data Subject's Rights - Data Access,
                                Portability and Deletion
                              </a>
                            </li>
                            <li>
                              <a href="#Contact-Us">Contact-Us</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-8">
                          <div
                            className="text-section"
                            id="Definitions-and-key-terms"
                          >
                            <p>
                              <label>Definitions and key terms </label>
                              To help explain things as clearly as possible in
                              this Privacy Policy, every time any of these terms
                              are referenced, are strictly defined as:
                              <br />
                              <br />
                              <ul className="first-list">
                                <li>
                                  Cookie: small amount of data generated by a
                                  website and saved by your web browser. It is
                                  used to identify your browser, provide
                                  analytics, and remember information about you
                                  such as your language preference or login
                                  information.
                                </li>
                                <li>
                                  Company: when this policy mentions “Company,”
                                  “we,” “us,” or “our,” it refers to Tribel LLC.
                                  that is responsible for your information under
                                  this Privacy Policy.
                                </li>
                                <li>
                                  Country: where Tribel or the owners/founders
                                  of Tribel are based, in this case is USA
                                </li>
                                <li>
                                  Customer: refers to the company, organization
                                  or person that signs up to use the Tribel
                                  Service to manage the relationships with your
                                  consumers or service users.
                                </li>
                                <li>
                                  Device: any internet connected device such as
                                  a phone, tablet, computer or any other device
                                  that can be used to visit Tribel and use the
                                  services.
                                </li>
                                <li>
                                  IP address: Every device connected to the
                                  Internet is assigned a number known as an
                                  Internet protocol (IP) address. These numbers
                                  are usually assigned in geographic blocks. An
                                  IP address can often be used to identify the
                                  location from which a device is connecting to
                                  the Internet.
                                </li>
                                <li>
                                  Personnel: refers to those individuals who are
                                  employed by Tribel or are under contract to
                                  perform a service on behalf of one of the
                                  parties.
                                </li>
                                <li>
                                  Personal Data: any information that directly,
                                  indirectly, or in connection with other
                                  information — including a personal
                                  identification number — allows for the
                                  identification or identifiability of a natural
                                  person
                                </li>
                                <li>
                                  Service: refers to the service provided by
                                  Tribel as described in the relative terms (if
                                  available) and on this platform.
                                </li>
                                <li>
                                  Third-party service: refers to advertisers,
                                  contest sponsors, promotional and marketing
                                  partners, and others who provide our content
                                  or whose products or services we think may
                                  interest you.
                                </li>
                                <li>
                                  Website: Tribel."’s" site, which can be
                                  accessed via this URL: https://www.tribel.com/
                                </li>
                                <li>
                                  You: a person or entity that is registered
                                  with Tribel to use the Services.
                                </li>
                              </ul>
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="What-Information-Do-We-Collect"
                          >
                            <label>What Information Do We Collect?</label>
                            <br />
                            <p>
                              We collect data and information from you through
                              our interactions with you and through our services
                              for a variety of purposes described below,
                              including to operate effectively and provide you
                              with the best experiences with our Service. You
                              provide some of this data directly, such as when
                              register on our site, place an order, subscribe to
                              our newsletter, respond to a survey or fill out a
                              form. We get some of it by collecting data about
                              your interactions, use, and experience with our
                              services and communications and when you visit our
                              site.
                            </p>
                            <p>
                              We rely on a variety of legal reasons and
                              permissions (sometimes called “legal bases”) to
                              process data, including with your consent, a
                              balancing of legitimate interests, necessity to
                              enter into and perform contracts, and compliance
                              with legal obligations, for a variety of purposes
                              described below.
                            </p>
                            <p>
                              We also obtain data from third parties. We protect
                              data obtained from third parties according to the
                              practices described in this statement, plus any
                              additional restrictions imposed by the source of
                              the data. These third-party sources vary over time
                              and include:
                            </p>
                            <ul className="bulat">
                              <li>
                                Data brokers from which we get demographic data
                                to supplement the data we collect.
                              </li>
                              <li>
                                Services that make user-generated content from
                                their service available to others, such as local
                                business reviews or public social media posts.
                              </li>
                              <li>
                                Communication services, including email
                                providers and social networks, when you give us
                                permission to access your data on such
                                third-party services or networks.
                              </li>
                              <li>
                                Service providers that help us determine your
                                device’s location.
                              </li>
                              <li>
                                Partners with which we offer co-branded services
                                or engage in joint marketing activities.
                              </li>
                              <li>
                                Third parties that deliver experiences through
                                services provided by Tribel
                              </li>
                            </ul>
                            <p>
                              You have choices when it comes to the technology
                              you use and the data you share. When you are asked
                              to provide personal data, you can decline and You
                              can control how much of your information you wish
                              to provide by changing your privacy settings.
                            </p>
                            <p>
                              Our service require some personal data to operate
                              and provide you with the service. If you choose
                              not to provide data required to operate and
                              provide you with a feature, you cannot use that
                              feature. Likewise, where we need to collect
                              personal data by law or to enter into or carry out
                              a contract with you, and you do not provide the
                              data, we will not be able to enter into the
                              contract; or if this relates to an existing you’re
                              using, we may have to suspend or cancel it. We
                              will notify you if this is the case at the time.
                              Where providing the data is optional, and you
                              choose not to share personal data, features like
                              personalization that use the data will not work
                              for you.
                            </p>
                            <p className="text-italic">
                              The data we collect depends on the context of your
                              interactions with Tribel and the choices you
                              make (including your privacy settings), the
                              service and features you use, your location, and
                              applicable law.
                            </p>
                            <p>
                              The data we collect can include the following:
                            </p>
                            <p>
                              <strong>Name and contact data. </strong> Your
                              first and last name, email address, postal
                              address, phone number, and other similar contact
                              data.
                            </p>
                            <p>
                              <strong>Credentials.</strong> Passwords, password
                              hints, and similar security information used for
                              authentication and account access.
                            </p>
                            <p>
                              <strong>Demographic data. </strong>Data about you
                              such as your age, gender, country, and preferred
                              language.
                            </p>
                            <p>
                              <strong>Interactions.</strong> Data about your use
                              of our service. In some cases, such as search
                              queries, this is data you provide in order to make
                              use of the service. In other cases, such as error
                              reports, this is data we generate. Other examples
                              of interactions data include:
                            </p>
                            <ul className="bulat">
                              <li>
                                <div>
                                  <p>
                                    <strong>Device and usage data. </strong>{" "}
                                    Data about your device and the service and
                                    features you use, including information
                                    about your hardware and software, how our
                                    service perform, as well as your settings.
                                    For example:{" "}
                                  </p>
                                  <ul className="bulit2">
                                    <li>
                                      <strong>Account history.</strong> Data
                                      about the activities associated with your
                                      account.
                                    </li>
                                    <li>
                                      <strong>Browse history. </strong> Data
                                      about the webpages you visit.
                                    </li>
                                    <li>
                                      <strong>
                                        Device, connectivity, and configuration
                                        data.
                                      </strong>{" "}
                                      Data about your device, your device
                                      configuration, and nearby networks. For
                                      example, data about the operating systems
                                      and other software installed on your
                                      device, including product keys. In
                                      addition, IP address, device identifiers
                                      (such as the IMEI number for phones),
                                      regional and language settings, and
                                      information about WLAN access points near
                                      your device.
                                    </li>
                                    <li>
                                      <strong>
                                        Error reports and performance data.
                                      </strong>{" "}
                                      Data about the performance of the products
                                      and any problems you experience, including
                                      error reports, contents of files opened
                                      when an error occurred, and data about
                                      other software on your device.
                                    </li>
                                    <li>
                                      <strong>
                                        Troubleshooting and help data.{" "}
                                      </strong>{" "}
                                      Data you provide when you contact Tribel
                                      for support. For example, contact or
                                      authentication data, the content of your
                                      chats and other communications with
                                      Tribel, data about the condition of your
                                      device, and the features you use related
                                      to your help inquiry.{" "}
                                    </li>
                                    <li>
                                      <strong>Bot usage data.</strong>{" "}
                                      Interactions with third party bots.
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li>
                                <strong>Interests and favorites.</strong> Data
                                about your interests and favorites, such as the
                                personalities you follow, the languages you
                                prefer, the posts you track. In addition to
                                those you explicitly provide, your interests and
                                favorites can also be inferred or derived from
                                other data we collect.
                              </li>
                              <li>
                                <strong>Content consumption data. </strong>
                                Information about media content you access
                                through Tribel service.
                              </li>
                              <li>
                                <strong>Searches and commands. </strong>Search
                                queries and commands when you use Tribel service
                                with search or related productivity
                                functionality.
                              </li>
                              <li>
                                <strong>Text, inking, and typing data. </strong>{" "}
                                Text, inking, and typing data and related
                                information. For example, when we collect inking
                                data, we collect information about the placement
                                of your inking instrument on your device.
                              </li>
                              <li>
                                <strong>Images.</strong> Images and related
                                information, such as picture metadata. For
                                example, we collect the image you provide when
                                you use Tribel services.
                              </li>
                              <li>
                                <strong>Contacts and relationships.</strong>{" "}
                                Data about your contacts and relationships if
                                you use a feature to share information with
                                others, manage contacts, communicate with
                                others, or improve your productivity.
                              </li>
                              <li>
                                <strong>Social data.</strong> Information about
                                your relationships and interactions between you,
                                other people, and organizations, such as types
                                of engagement (e.g., likes, dislikes, events,
                                etc.) related to people and organizations.
                              </li>
                              <li>
                                <strong>Location data.</strong> Data about your
                                device’s location, which can be either precise
                                or imprecise. For example, we collect location
                                data using Global Navigation Satellite System
                                (GNSS) (e.g., GPS) and data about nearby cell
                                towers and Wi-Fi hotspots. Location can also be
                                inferred from a device’s IP address or data in
                                your account profile that indicates where it is
                                located with less precision, such as at a city
                                or postal code level.
                              </li>
                            </ul>
                            <p>
                              <strong>Content.</strong> Content of your files
                              and communications you input, upload, receive,
                              create, and control. For example, if you post or
                              transmit a file through Tribel, we need to collect
                              the content of that file to display it to you and
                              the other user. Other content we collect when
                              providing products to you include:
                            </p>
                            <ul className="bulat">
                              <li>
                                Communications, including audio, video, text
                                (typed, inked, dictated, or otherwise), in a
                                message, email, call, meeting request, or chat.
                              </li>
                              <li>
                                Photos, images, songs, movies, software, and
                                other media or documents you store, retrieve, or
                                otherwise process with our cloud.
                              </li>
                            </ul>
                            <p>
                              <strong>Feedback and ratings.</strong> Information
                              you provide to us and the content of messages you
                              send to us, such as feedback, survey data, and
                              reviews you write.
                            </p>
                            <p>
                              We also collect information from mobile devices
                              for a better user experience, although these
                              features are completely optional:
                            </p>
                            <p>
                              Photo Gallery (Pictures & Videos): Granting photo
                              gallery access allows the user to upload any
                              picture or photo from their photo gallery, you can
                              safely deny photo gallery access for this
                              website/app.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="How-Do-We-Use-The-Information-We-Collect"
                          >
                            <label>
                              How Do We Use The Information We Collect?
                            </label>
                            <p>
                              Tribel uses the data we collect to provide you
                              rich, interactive experiences. In particular, we
                              use data to:
                            </p>
                            <ul className="bulat">
                              <li>
                                Provide our Services, which includes updating,
                                securing, and troubleshooting, as well as
                                providing support. It also includes sharing
                                data, when it is required to provide the service
                                or carry out the transmission you request.
                              </li>
                              <li>
                                Improve and develop our services and features.
                              </li>
                              <li>
                                Personalize our services and make
                                recommendations.
                              </li>
                              <li>
                                Advertise and market to you, which includes
                                sending promotional communications, targeting
                                advertising, and presenting you relevant offers.
                              </li>
                            </ul>
                            <p>
                              We also use the data to operate our business,
                              which includes analyzing our performance and
                              meeting our legal obligations.
                            </p>
                            <p>
                              We also store data we collect from you when you
                              are unauthenticated (not signed in) separately
                              from any account information that directly
                              identifies you, such as your name, email address,
                              or phone number.
                            </p>
                            <p>
                              Our processing of personal data for these purposes
                              includes both automated and manual (human) methods
                              of processing. Our automated methods often are
                              related to and supported by our manual methods.
                              For example, our automated methods include
                              artificial intelligence (AI), which we think of as
                              a set of technologies that enable computers to
                              perceive, learn, reason, and assist in
                              decision-making to solve problems in ways that are
                              similar to what people do. To build, train, and
                              improve the accuracy of our automated methods of
                              processing (including AI), we manually review some
                              of the predictions and inferences produced by the
                              automated methods against the underlying data from
                              which the predictions and inferences were made.{" "}
                            </p>
                            <p>
                              When we process personal data about you, we do so
                              with your consent and/or as required to provide
                              the services you use, operate our business, meet
                              our contractual and legal obligations, protect the
                              security of our systems and our customers, or
                              fulfill other legitimate interests of Tribel as
                              described in this privacy statement.{" "}
                            </p>
                            <strong>More on the purposes of processing:</strong>
                            <ul>
                              <li>
                                <strong>Provide our products.</strong> We use
                                data to operate our Service and provide you with
                                rich, interactive experiences. For example, if
                                you enter a search query in the search box, we
                                use that query to display search results to you.
                                We also communicate with you to secure our
                                service, for example by letting you know when
                                product updates are available.
                              </li>
                              <li>
                                <strong>Service improvement.</strong> We use
                                data to continually improve our service,
                                including adding new features or capabilities.
                                For example, we use error reports to improve
                                security features, search queries and usage data
                                to determine what new features to prioritize.
                              </li>
                              <li>
                                <strong>Personalization.</strong> Our service
                                includes personalized features, such as
                                recommendations that enhance your enjoyment or
                                productivity. These features use automated
                                processes to tailor your service experiences
                                based on the data we have about you, such as
                                inferences we make about you and your use of the
                                service, activities, interests, and location. We
                                also use data—such as device and application
                                type, location, and unique device, application
                                and network for this same purpose
                              </li>
                              <li>
                                <strong>Introducing new features.</strong> We
                                use data to introduce new features. For example,
                                we use data, often de-identified, to better
                                understand our customers’ experience needs which
                                can shape the development of new features.
                              </li>
                              <li>
                                <strong>Customer support.</strong> We use data
                                to provide customer support services, including
                                to help us provide, improve, and secure the
                                quality of our service and to investigate
                                security incidents.{" "}
                              </li>
                              <li>
                                <strong>Updates.</strong> We use data we collect
                                to develop service updates and security patches.
                                For example, we may use information about your
                                device’s capabilities, such as operating system
                                version, to provide you an update or security
                                patch. Updates and patches are intended to
                                maximize your experience with our service, help
                                you protect the privacy and security of your
                                data, provide new features, and ensure your
                                device is ready to process such updates.
                              </li>
                              <li>
                                <strong>Advertising.</strong> Tribel does not
                                use what you say in email, chat, video calls, or
                                voice mail, or your documents, photos, or other
                                personal files to target ads to you. We use data
                                we collect through our interactions with you,
                                through some of our products, and on third-party
                                web properties, for advertising in our products
                                and on third-party properties. We may use
                                automated processes to help make advertising
                                more relevant to you.
                              </li>
                              <li>
                                <strong>
                                  Reporting and business operations.{" "}
                                </strong>{" "}
                                We use data to analyze our operations and
                                perform business intelligence. This enables us
                                to make informed decisions and report on the
                                performance of our business.
                              </li>
                              <li>
                                <strong>Protecting rights and property.</strong>{" "}
                                We use data to detect and prevent fraud, resolve
                                disputes, enforce agreements, and protect our
                                property. We may use automated processes to
                                detect and prevent activities that violate our
                                rights and the rights of others, such as fraud.
                              </li>
                              <li>
                                <strong>Legal compliance.</strong>We process
                                data to comply with law. For example, we use the
                                age of our customers to ensure we meet our
                                obligations to protect children’s privacy. We
                                also process contact information and credentials
                                to help customers exercise their data protection
                                rights.
                              </li>
                              <li>
                                <strong>Research.</strong> With appropriate
                                technical and organizational measures to
                                safeguard individuals’ rights and freedoms, we
                                may use data to conduct research, including for
                                public interest and scientific purposes.
                              </li>
                            </ul>
                          </div>
                          <div
                            className="text-section"
                            id="When-does-Tribel-use-end-user-information-from-third-parties-and-public-Network"
                          >
                            <label>
                              When does Tribel use end user information from
                              third parties and public Network?
                            </label>
                            <p>
                              End users may voluntarily provide us with
                              information they have made available on social
                              media websites such as Name/Username, Email
                              Addresses, County and State, Age, Gender and other
                              relevant data that end user wish to share
                              voluntarily. If you provide us with any such
                              information, we may collect publicly available
                              information from the social media websites you
                              have indicated. You can control how much of your
                              information social media websites make public by
                              visiting these websites and changing your privacy
                              settings.
                            </p>

                            <br />
                            <br />
                            <strong>
                              When does Tribel use customer information from
                              third parties?
                            </strong>
                            <br />
                            <br />
                            <p>
                              We receive some information from the third parties
                              when you contact us. For example, when you submit
                              your email address to us to show interest in
                              becoming a Tribel customer, we receive information
                              from a third party that provides automated fraud
                              detection services to Tribel. We also occasionally
                              collect information that is made publicly
                              available on social media websites. You can
                              control how much of your information social media
                              websites make public by visiting these websites
                              and changing your privacy settings.
                            </p>
                            <br />
                            <br />
                            <strong>
                              Do we share the information we collect with third
                              parties?
                            </strong>
                            <br />
                            <br />
                            <p>
                              We receive some information from the third parties
                              when you contact us. For example, when you submit
                              your email address to us to show interest in
                              becoming a Tribel customer, we receive information
                              from a third party that provides automated fraud
                              detection services to Tribel. We also occasionally
                              collect information that is made publicly
                              available on social media websites. You can
                              control how much of your information social media
                              websites make public by visiting these websites
                              and changing your privacy settings.
                            </p>
                            <br />
                            <br />
                            <strong>
                              Do we share the information we collect with third
                              parties?
                            </strong>
                            <br />
                            <br />
                            <p>
                              We may share the information that we collect, both
                              personal and non-personal, with third parties such
                              as advertisers, contest sponsors, promotional and
                              marketing partners, and others who provide our
                              content or whose products or services we think may
                              interest you. We may also share it with our
                              current and future affiliated companies and
                              business partners, and if we are involved in a
                              merger, asset sale or other business
                              reorganization, we may also share or transfer your
                              personal and non-personal information to our
                              successors-in-interest.
                            </p>
                            <p>
                              We may engage trusted third party service
                              providers to perform functions and provide
                              services to us, such as hosting and maintaining
                              our servers and the website/app, database storage
                              and management, e-mail management, storage
                              marketing, credit card processing, customer
                              service and fulfilling orders for products and
                              services you may purchase through the website/app.
                              We will likely share your personal information,
                              and possibly some non-personal information, with
                              these third parties to enable them to perform
                              these services for us and for you.
                            </p>
                            <p>
                              We may share portions of our log file data,
                              including IP addresses, for analytics purposes
                              with third parties such as web analytics partners,
                              application developers, and ad networks. If your
                              IP address is shared, it may be used to estimate
                              general location and other technographics such as
                              connection speed, whether you have visited the
                              website/app in a shared location, and type of the
                              device used to visit the website/app. They may
                              aggregate information about our advertising and
                              what you see on the website/app and then provide
                              auditing, research and reporting for us and our
                              advertisers.
                            </p>
                            <p>
                              We may also disclose personal and non-personal
                              information about you to government or law
                              enforcement officials or private parties as we, in
                              our sole discretion, believe necessary or
                              appropriate in order to respond to claims, legal
                              process (including subpoenas), to protect our
                              rights and interests or those of a third party,
                              the safety of the public or any person, to prevent
                              or stop any illegal, unethical, or legally
                              actionable activity, or to otherwise comply with
                              applicable court orders, laws, rules and
                              regulations.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Where-and-when-is-information-collected-from-customers-and-end-users"
                          >
                            <label>
                              Where and when is information collected from
                              customers and end users?
                            </label>
                            <p>
                              Tribel will collect personal information that you
                              submit to us. We may also receive personal
                              information about you from third parties as
                              described above.
                            </p>
                            <p>How Do We Use Your Email Address?</p>
                            <p>
                              By submitting your email address on this
                              website/app, you agree to receive emails from us.
                              You can cancel your participation in any of these
                              email lists at any time by clicking on the opt-out
                              link or other unsubscribe option that is included
                              in the respective email. We only send emails to
                              people who have authorized us to contact them,
                              either directly, or through a third party. We do
                              not send unsolicited commercial emails, because we
                              hate spam as much as you do. By submitting your
                              email address, you also agree to allow us to use
                              your email address for customer audience targeting
                              on sites like Facebook, where we display custom
                              advertising to specific people who have opted-in
                              to receive communications from us. Email addresses
                              submitted only through the order processing page
                              will be used for the sole purpose of sending you
                              information and updates pertaining to your order.
                              If, however, you have provided the same email to
                              us through another method, we may use it for any
                              of the purposes stated in this Policy. Note: If at
                              any time you would like to unsubscribe from
                              receiving future emails, we include detailed
                              unsubscribe instructions at the bottom of each
                              email.
                            </p>
                            <p>How Long Do We Keep Your Information?</p>
                            <p>
                              We keep your information only so long as we need
                              it to provide Tribel to you and fulfill the
                              purposes described in this policy. This is also
                              the case for anyone that we share your information
                              with and who carries out services on our behalf.
                              When we no longer need to use your information and
                              there is no need for us to keep it to comply with
                              our legal or regulatory obligations, we’ll either
                              remove it from our systems or depersonalize it so
                              that we can't identify you.
                            </p>
                            <p>How Do We Protect Your Information?</p>
                            <p>
                              We implement a variety of security measures to
                              maintain the safety of your personal information
                              when you place an order or enter, submit, or
                              access your personal information. We offer the use
                              of a secure server. All supplied sensitive/credit
                              information is transmitted via Secure Socket Layer
                              (SSL) technology and then encrypted into our
                              Payment gateway providers database only to be
                              accessible by those authorized with special access
                              rights to such systems, and are required to keep
                              the information confidential. After a transaction,
                              your private information (credit cards, social
                              security numbers, financials, etc.) is never kept
                              on file. We cannot, however, ensure or warrant the
                              absolute security of any information you transmit
                              to Tribel or guarantee that your information on
                              the Service may not be accessed, disclosed,
                              altered, or destroyed by a breach of any of our
                              physical, technical, or managerial safeguards.
                            </p>
                            <p>
                              Could my information be transferred to other
                              countries?
                            </p>
                            <p>
                              Tribel is incorporated in USA. Information
                              collected via our website, through direct
                              interactions with you, or from use of our help
                              services may be transferred from time to time to
                              our offices or personnel, or to third parties,
                              located throughout the world, and may be viewed
                              and hosted anywhere in the world, including
                              countries that may not have laws of general
                              applicability regulating the use and transfer of
                              such data. To the fullest extent allowed by
                              applicable law, by using any of the above, you
                              voluntarily consent to the trans-border transfer
                              and hosting of such information.
                            </p>
                            <p>
                              Is the information collected through the Tribel
                              Service secure?
                            </p>
                            <p>
                              We take precautions to protect the security of
                              your information. We have physical, electronic,
                              and managerial procedures to help safeguard,
                              prevent unauthorized access, maintain data
                              security, and correctly use your information.
                              However, neither people nor security systems are
                              foolproof, including encryption systems. In
                              addition, people can commit intentional crimes,
                              make mistakes or fail to follow policies.
                              Therefore, while we use reasonable efforts to
                              protect your personal information, we cannot
                              guarantee its absolute security. If applicable law
                              imposes any non-disclaimable duty to protect your
                              personal information, you agree that intentional
                              misconduct will be the standards used to measure
                              our compliance with that duty.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Can-I-update-or-correct-my-information"
                          >
                            <label>
                              Can I update or correct my information?
                            </label>
                            <p>
                              The rights you have to request updates or
                              corrections to the information Tribel collects
                              depend on your relationship with Tribel. Personnel
                              may update or correct their information as
                              detailed in our internal company employment
                              policies.
                            </p>
                            <p>
                              Customers have the right to request the
                              restriction of certain uses and disclosures of
                              personally identifiable information as follows.
                              You can contact us in order to (1) update or
                              correct your personally identifiable information,
                              (2) change your preferences with respect to
                              communications and other information you receive
                              from us, or (3) delete the personally identifiable
                              information maintained about you on our systems
                              (subject to the following paragraph),
                              by cancelling your account. Such updates,
                              corrections, changes and deletions will have no
                              effect on other information that we maintain, or
                              information that we have provided to third parties
                              in accordance with this Privacy Policy prior to
                              such update, correction, change or deletion. To
                              protect your privacy and security, we may take
                              reasonable steps (such as requesting a unique
                              password) to verify your identity before granting
                              you profile access or making corrections. You are
                              responsible for maintaining the secrecy of your
                              unique password and account information at all
                              times.
                            </p>
                            <p>
                              You should be aware that it is not technologically
                              possible to remove each and every record of the
                              information you have provided to us from our
                              system. The need to back up our systems to protect
                              information from inadvertent loss means that a
                              copy of your information may exist in a
                              non-erasable form that will be difficult or
                              impossible for us to locate. Promptly after
                              receiving your request, all personal information
                              stored in databases we actively use, and other
                              readily searchable media will be updated,
                              corrected, changed or deleted, as appropriate, as
                              soon as and to the extent reasonably and
                              technically practicable.
                            </p>
                            <p>
                              If you are an end user and wish to update, delete,
                              or receive any information we have about you, you
                              may do so by contacting the organization of which
                              you are a customer.
                            </p>
                            <p>
                              Personnel If you are a Tribel worker or applicant,
                              we collect information you voluntarily provide to
                              us. We use the information collected for Human
                              Resources purposes in order to administer benefits
                              to workers and screen applicants.
                            </p>
                            <p>
                              You may contact us in order to (1) update or
                              correct your information, (2) change your
                              preferences with respect to communications and
                              other information you receive from us, or (3)
                              receive a record of the information we have
                              relating to you. Such updates, corrections,
                              changes and deletions will have no effect on other
                              information that we maintain, or information that
                              we have provided to third parties in accordance
                              with this Privacy Policy prior to such update,
                              correction, change or deletion.
                            </p>
                          </div>
                          <div className="text-section" id="Sale-of-Business">
                            <label>Sale of Business</label>
                            <p>
                              We reserve the right to transfer information to a
                              third party in the event of a sale, merger or
                              other transfer of all or substantially all of the
                              assets of Tribel or any of its Corporate
                              Affiliates (as defined herein), or that portion of
                              Tribel or any of its Corporate Affiliates to which
                              the Service relates, or in the event that we
                              discontinue our business or file a petition or
                              have filed against us a petition in bankruptcy,
                              reorganization or similar proceeding, provided
                              that the third party agrees to adhere to the terms
                              of this Privacy Policy.
                            </p>
                          </div>
                          <div className="text-section" id="Affiliates">
                            <label>Affiliates</label>
                            <p>
                              We may disclose information (including personal
                              information) about you to our Corporate
                              Affiliates. For purposes of this Privacy Policy,
                              "Corporate Affiliate" means any person or entity
                              which directly or indirectly controls, is
                              controlled by or is under common control with
                              Tribel, whether by ownership or otherwise. Any
                              information relating to you that we provide to our
                              Corporate Affiliates will be treated by those
                              Corporate Affiliates in accordance with the terms
                              of this Privacy Policy.
                            </p>
                          </div>
                          <div className="text-section" id="Governing-Law">
                            <label>Governing Law</label>
                            <p>
                              We may disclose information (including personal
                              information) about you to our Corporate
                              Affiliates. For purposes of this Privacy Policy,
                              "Corporate Affiliate" means any person or entity
                              which directly or indirectly controls, is
                              controlled by or is under common control with
                              Tribel, whether by ownership or otherwise. Any
                              information relating to you that we provide to our
                              Corporate Affiliates will be treated by those
                              Corporate Affiliates in accordance with the terms
                              of this Privacy Policy.
                            </p>
                            <p>
                              The laws of USA, excluding its conflicts of law
                              rules, shall govern this Agreement and your use of
                              the website/app. Your use of the website/app may
                              also be subject to other local, state, national,
                              or international laws.
                            </p>
                            <p>
                              By using Tribel or contacting us directly, you
                              signify your acceptance of this Privacy Policy. If
                              you do not agree to this Privacy Policy, you
                              should not engage with our website, or use our
                              services. Continued use of the website, direct
                              engagement with us, or following the posting of
                              changes to this Privacy Policy that do not
                              significantly affect the use or disclosure of your
                              personal information will mean that you accept
                              those changes.
                            </p>
                          </div>
                          <div className="text-section" id="Your-Consent">
                            <label>Your Consent</label>
                            <p>
                              We've updated our Privacy Policy to provide you
                              with complete transparency into what is being set
                              when you visit our site and how it's being used.
                              By using our website/app, registering an account,
                              or making a purchase, you hereby consent to our
                              Privacy Policy and agree to its terms.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Links-to-Other-Websites"
                          >
                            <label>Links to Other Websites</label>
                            <p>
                              This Privacy Policy applies only to the Services.
                              The Services may contain links to other websites
                              not operated or controlled by Tribel. We are not
                              responsible for the content, accuracy or opinions
                              expressed in such websites, and such websites are
                              not investigated, monitored or checked for
                              accuracy or completeness by us. Please remember
                              that when you use a link to go from the Services
                              to another website, our Privacy Policy is no
                              longer in effect. Your browsing and interaction on
                              any other website, including those that have a
                              link on our platform, is subject to that website’s
                              own rules and policies. Such third parties may use
                              their own cookies or other methods to collect
                              information about you.
                            </p>
                          </div>
                          <div className="text-section" id="Advertising">
                            <label>Advertising</label>
                            <p>
                              This website/app may contain third party
                              advertisements and links to third party sites.
                              Tribel does not make any representation as to the
                              accuracy or suitability of any of the information
                              contained in those advertisements or sites and
                              does not accept any responsibility or liability
                              for the conduct or content of those advertisements
                              and sites and the offerings made by the third
                              parties.
                            </p>
                            <p>
                              Advertising keeps Tribel and many of the websites
                              and services you use free of charge. We work hard
                              to make sure that ads are safe, unobtrusive, and
                              as relevant as possible.
                            </p>
                            <p>
                              Third party advertisements and links to other
                              sites where goods or services are advertised are
                              not endorsements or recommendations by Tribel of
                              the third party sites, goods or services. Tribel
                              takes no responsibility for the content of any of
                              the ads, promises made, or the quality/reliability
                              of the products or services offered in all
                              advertisements.
                            </p>
                            <p>Cookies for Advertising</p>
                            <p>
                              These cookies collect information over time about
                              your online activity on the website/app and other
                              online services to make online advertisements more
                              relevant and effective to you. This is known as
                              interest-based advertising. They also perform
                              functions like preventing the same ad from
                              continuously reappearing and ensuring that ads are
                              properly displayed for advertisers. Without
                              cookies, it’s really hard for an advertiser to
                              reach its audience, or to know how many ads were
                              shown and how many clicks they received.
                            </p>
                          </div>
                          <div className="text-section" id="Cookies">
                            <label>Cookies</label>
                            <p>
                              Tribel uses "Cookies" to identify the areas of our
                              website that you have visited. A Cookie is a small
                              piece of data stored on your computer or mobile
                              device by your web browser. We use Cookies to
                              enhance the performance and functionality of our
                              website/app but are non-essential to their use.
                              However, without these cookies, certain
                              functionality like videos may become unavailable
                              or you would be required to enter your login
                              details every time you visit the website/app as we
                              would not be able to remember that you had logged
                              in previously. Most web browsers can be set to
                              disable the use of Cookies. However, if you
                              disable Cookies, you may not be able to access
                              functionality on our website correctly or at all.
                              We never place Personally Identifiable Information
                              in Cookies.
                            </p>
                            <p>
                              Blocking and disabling cookies and similar
                              technologies
                            </p>
                            <p>
                              Wherever you're located you may also set your
                              browser to block cookies and similar technologies,
                              but this action may block our essential cookies
                              and prevent our website from functioning properly,
                              and you may not be able to fully utilize all of
                              its features and services. You should also be
                              aware that you may also lose some saved
                              information (e.g. saved login details, site
                              preferences) if you block cookies on your browser.
                              Different browsers make different controls
                              available to you. Disabling a cookie or category
                              of cookie does not delete the cookie from your
                              browser, you will need to do this yourself from
                              within your browser, you should visit your
                              browser's help menu for more information.
                            </p>
                            <p>Remarketing Services</p>
                            <p>
                              We use remarketing services. What Is Remarketing?
                              In digital marketing, remarketing (or retargeting)
                              is the practice of serving ads across the internet
                              to people who have already visited your website.
                              It allows your company to seem like they're
                              “following” people around the internet by serving
                              ads on the websites and platforms they use most.
                            </p>
                          </div>
                          <div className="text-section" id="Kids-Privacy">
                            <label>Kid's Privacy</label>
                            <p>
                              We do not address anyone under the age of 13. We
                              do not knowingly collect personally identifiable
                              information from anyone under the age of 13. If
                              You are a parent or guardian and You are aware
                              that Your child has provided Us with Personal
                              Data, please contact Us. If we become aware that
                              We have collected Personal Data from anyone under
                              the age of 13 without verification of parental
                              consent, We take steps to remove that information
                              from Our servers.
                            </p>
                          </div>
                          <div className="text-section">
                            <label>Changes to Our Privacy Policy</label>
                            <p>
                              We may change our Service and policies, and we may
                              need to make changes to this Privacy Policy so
                              that they accurately reflect our Service and
                              policies. Unless otherwise required by law, we
                              will notify you (for example, through our Service)
                              before we make changes to this Privacy Policy and
                              give you an opportunity to review them before they
                              go into effect. Then, if you continue to use the
                              Service, you will be bound by the updated Privacy
                              Policy. If you do not want to agree to this or any
                              updated Privacy Policy, you can delete your
                              account.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Third-Party-Services"
                          >
                            <label>Third-Party Services</label>
                            <p>
                              We may display, include or make available
                              third-party content (including data, information,
                              applications and other products services) or
                              provide links to third-party websites or services
                              ("Third- Party Services"). You acknowledge and
                              agree that Tribel shall not be responsible for any
                              Third-Party Services, including their accuracy,
                              completeness, timeliness, validity, copyright
                              compliance, legality, decency, quality or any
                              other aspect thereof. Tribel does not assume and
                              shall not have any liability or responsibility to
                              you or any other person or entity for any
                              Third-Party Services.
                            </p>
                            <p>
                              Third-Party Services and links thereto are
                              provided solely as a convenience to you and you
                              access and use them entirely at your own risk and
                              subject to such third parties' terms and
                              conditions.
                            </p>
                            <p>Facebook Pixel</p>
                            <p>
                              Facebook pixel is an analytics tool that allows
                              you to measure the effectiveness of your
                              advertising by understanding the actions people
                              take on your website. You can use the pixel to:
                              Make sure your ads are shown to the right people.
                              Facebook pixel may collect information from your
                              device when you use the service. Facebook pixel
                              collects information that is held in accordance
                              with its Privacy Policy
                            </p>
                            <p>Tracking Technologies</p>
                            <p>&nbsp;-Google Maps API</p>
                            <p>
                              &nbsp;Google Maps API is a robust tool that can be
                              used to create a custom map, a searchable map,
                              check-in functions, display live data synching
                              with location, plan routes, or create a mashup
                              just to name a few.
                            </p>
                            <p>
                              &nbsp;Google Maps API may collect information from
                              You and from Your Device for security purposes.
                            </p>
                            <p>
                              &nbsp;Google Maps API collects information that is
                              held in accordance with its Privacy Policy.
                            </p>
                            <p>&nbsp; -Cookies</p>
                            <p>
                              &nbsp; We use Cookies to enhance the performance
                              and functionality of our website/app but are
                              non-essential to their use. However, without these
                              cookies, certain functionality like videos may
                              become unavailable or you would be required to
                              enter your login details every time you visit the
                              website/app as we would not be able to remember
                              that you had logged in previously.
                            </p>
                            <p>&nbsp; -Local Storage</p>
                            <p>
                              &nbsp; Local Storage sometimes known as DOM
                              storage, provides web apps with methods and
                              protocols for storing client-side data. Web
                              storage supports persistent data storage, similar
                              to cookies but with a greatly enhanced capacity
                              and no information stored in the HTTP request
                              header.
                            </p>
                            <p>&nbsp; -Sessions</p>
                            <p>
                              &nbsp; -Tribel uses "Sessions" to identify the
                              areas of our website that you have visited. A
                              Session is a small piece of data stored on your
                              computer or mobile device by your web browser.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Information-about-General-Data-Protection-Regulation-GDPR"
                          >
                            <label>
                              Information about General Data Protection
                              Regulation (GDPR)
                            </label>
                            <p>
                              We may be collecting and using information from
                              you if you are from the European Economic Area
                              (EEA), and in this section of our Privacy Policy
                              we are going to explain exactly how and why is
                              this data collected, and how we maintain this data
                              under protection from being replicated or used in
                              the wrong way.
                            </p>
                            <p>What is GDPR?</p>
                            <p>
                              GDPR is an EU-wide privacy and data protection law
                              that regulates how EU residents' data is protected
                              by companies and enhances the control the EU
                              residents have, over their personal data.
                            </p>
                            <p>
                              The GDPR is relevant to any globally operating
                              company and not just the EU-based businesses and
                              EU residents. Our customers’ data is important
                              irrespective of where they are located, which is
                              why we have implemented GDPR controls as our
                              baseline standard for all our operations
                              worldwide.
                            </p>
                            <p>What is personal data?</p>
                            <p>
                              Any data that relates to an identifiable or
                              identified individual. GDPR covers a broad
                              spectrum of information that could be used on its
                              own, or in combination with other pieces of
                              information, to identify a person. Personal data
                              extends beyond a person’s name or email address.
                              Some examples include financial information,
                              political opinions, genetic data, biometric data,
                              IP addresses, physical address, sexual
                              orientation, and ethnicity.
                            </p>
                            <p>
                              The Data Protection Principles include
                              requirements such as:
                            </p>
                            <p>
                              &nbsp;-Personal data collected must be processed
                              in a fair, legal, and transparent way and should
                              only be used in a way that a person would
                              reasonably expect.
                            </p>
                            <p>
                              &nbsp;-Personal data should only be collected to
                              fulfil a specific purpose and it should only be
                              used for that purpose. Organizations must specify
                              why they need the personal data when they collect
                              it. -Personal data should be held no longer than
                              necessary to fulfil its purpose.
                            </p>
                            <p>
                              &nbsp;-People covered by the GDPR have the right
                              to access their own personal data. They can also
                              request a copy of their data, and that their data
                              be updated, deleted, restricted, or moved to
                              another organization.
                            </p>
                            <p>Why is GDPR important?</p>
                            <p>
                              GDPR adds some new requirements regarding how
                              companies should protect individuals' personal
                              data that they collect and process. It also raises
                              the stakes for compliance by increasing
                              enforcement and imposing greater fines for breach.
                              Beyond these facts it's simply the right thing to
                              do. At Help Scout we strongly believe that your
                              data privacy is very important and we already have
                              solid security and privacy practices in place that
                              go beyond the requirements of this new regulation.
                            </p>
                          </div>
                          <div
                            className="text-section"
                            id="Individual-Data-Subjects-Rights-Data-Access-Portability-and-Deletion"
                          >
                            <label>
                              Individual Data Subject's Rights - Data Access,
                              Portability and Deletion
                            </label>
                            <p>
                              We are committed to helping our customers meet
                              the data subject rights requirements of GDPR.
                              Tribel processes or stores all personal data in
                              fully vetted, DPA compliant vendors. We do not
                              store any personal data after account is deleted.
                              We are aware that if you are working with EU
                              customers, you need to be able to provide them
                              with the ability to access, update, retrieve and
                              remove personal data. We got you! You can update
                              or delete some personal data from your profile and
                              to delete the rest of the data which is necessary
                              to pursue our service, you will be needing to
                              delete your account from Tribel. Our
                              customer support team is here for you to answer
                              any questions you might have about working with
                              the API.
                            </p>
                            <p>California Residents</p>
                            <p>
                              The California Consumer Privacy Act (CCPA)
                              requires us to disclose categories of Personal
                              Information we collect and how we use it, the
                              categories of sources from whom we collect
                              Personal Information, and the third parties with
                              whom we share it, which we have explained above.
                            </p>
                            <p>
                              We are also required to communicate information
                              about rights California residents have under
                              California law. You may exercise the following
                              rights:
                            </p>
                            <p>
                              &nbsp;-Right to Know and Access. You may submit a
                              verifiable request for information regarding the:
                              (1) categories of Personal Information we collect,
                              use, or share; (2) purposes for which categories
                              of Personal Information are collected or used by
                              us; (3) categories of sources from which we
                              collect Personal Information; and (4) specific
                              pieces of Personal Information we have collected
                              about you.
                            </p>
                            <p>
                              &nbsp;-Right to Equal Service. We will not
                              discriminate against you if you exercise your
                              privacy rights.
                            </p>
                            <p>
                              &nbsp;-Right to Delete. You may submit a
                              verifiable request to close your account and we
                              will delete Personal Information about you that we
                              have collected.
                            </p>
                            <p>
                              &nbsp;-Request that a business that sells a
                              consumer's personal data, not sell the consumer's
                              personal data.
                            </p>
                            <p>
                              &nbsp;-If you make a request, we have one month to
                              respond to you. If you would like to exercise any
                              of these rights, please contact us. We do not sell
                              the Personal Information of our users. For more
                              information about these rights, please contact us.
                            </p>
                          </div>
                          <div className="text-section" id="Contact-Us">
                            <label>Contact Us</label>
                            <p>
                              Don't hesitate to contact us if you have any
                              questions.
                            </p>
                            <p>
                              &nbsp;-Via Email: support@tribel.com &nbsp;-Via
                              this Link: https://www.tribel.com/contact
                            </p>
                            <br />
                            <p className="cento"> PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS. </p>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}
