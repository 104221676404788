import React from 'react'

function Contact() {
    return (
        <section className='terms_Privacy_Page'>
            <div className="portfolio_body contact_body">
                <div className="container custom-container">
                    <div className="main">
                        <div className="privacy_area main_topic_margin">
                            <div className="privacy_area_top text-center">
                                <h1>Contact Us</h1>
                            </div>
                            <div className="privacy_area_body_area">
                                <div className="privacy_area_body">
                                    <p className="text-center">
                                        If you have any questions, suggestions, or concerns,
                                        please send an email to{" "}
                                        <a href="mailto:support@tribel.com">support@tribel.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact