import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col sm={6}>
                        <h3 className="mobile">
                            Copyright © Tribel LLC
                        </h3></Col>
                    <Col sm={6}>
                        <ul className='footerNav'>
                            <li><a href='https://www.tribel.com'>Home</a>
                                <svg
                                    width="4"
                                    height="4"
                                    viewBox="0 0 4 4"
                                    fill="none"
                                    xmlns="https://www.w3.org/2000/svg"
                                >
                                    <circle cx="2" cy="2" r="2" fill="white" />
                                </svg></li>
                            <li><Link to="/">Terms Of Service</Link>
                                <svg
                                    width="4"
                                    height="4"
                                    viewBox="0 0 4 4"
                                    fill="none"
                                    xmlns="https://www.w3.org/2000/svg"
                                >
                                    <circle cx="2" cy="2" r="2" fill="white" />
                                </svg>
                            </li>
                            <li><Link to="/privacy">Privacy and Terms</Link>
                                <svg
                                    width="4"
                                    height="4"
                                    viewBox="0 0 4 4"
                                    fill="none"
                                    xmlns="https://www.w3.org/2000/svg"
                                >
                                    <circle cx="2" cy="2" r="2" fill="white" />
                                </svg>
                            </li>
                            <li><Link to="/Contact">contact</Link> </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
