import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from './component/Header';
import { Footer } from './component/Footer';
import { Terms } from './component/Terms';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Privacy } from './component/Privacy';
import Contact from './component/Contact';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App