import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Header = () => {
    return (
        <header>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <h1 className='logo'>
                            <svg version="1.1" id="Layer_1" xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 276 104" style={{ enableBackground: 'new 0 0 276 104' }} xmlSpace="preserve">
                                <g>
                                    <path style={{ fill: '#2286C9' }} d="M50.063,32.925c8.441,0,15.307-6.868,15.307-15.309S58.504,2.307,50.063,2.307
		S34.754,9.175,34.754,17.616S41.621,32.925,50.063,32.925z" />
                                    <path style={{ fill: '#2286C9' }} d="M92.014,38.253l-5.751-9.638l-0.088-0.147l-0.145,0.088c-10.838,6.469-23.276,9.888-35.967,9.888
		c-12.693,0-25.129-3.419-35.966-9.886l-0.145-0.088l-5.754,9.639l-0.087,0.147l0.147,0.088
		c10.97,6.548,23.348,10.402,36.101,11.292v50.868h11.408V49.635c12.753-0.89,25.131-4.744,36.101-11.294L92.014,38.253z" />
                                    <g>
                                        <path d="M107.409,52.298c-0.913-0.213-1.871-0.399-2.851-0.553c-1.003-0.154-1.993-0.278-2.941-0.367
			c-0.973-0.091-1.843-0.138-2.582-0.138c-3.898,0-7.502,0.356-10.71,1.057c-3.2,0.701-5.906,1.458-8.039,2.25l-0.11,0.042v45.913
			h11.426V62.07c0.549-0.169,1.449-0.366,2.744-0.597c1.365-0.242,2.666-0.365,3.864-0.365c2.105,0,3.988,0.169,5.599,0.501
			c1.604,0.334,2.876,0.655,3.784,0.956l0.182,0.061l1.961-9.533l0.032-0.149l-0.145-0.048
			C109.065,52.711,108.321,52.51,107.409,52.298z" />
                                        <path d="M122.853,31.359c-1.93,0-3.601,0.632-4.969,1.877c-1.375,1.249-2.072,2.987-2.072,5.165c0,2.118,0.696,3.825,2.072,5.074
			c1.369,1.246,3.04,1.878,4.969,1.878c1.868,0,3.509-0.632,4.879-1.878c1.375-1.252,2.072-2.959,2.072-5.074
			c0-2.175-0.696-3.912-2.072-5.165C126.362,31.991,124.721,31.359,122.853,31.359z" />
                                        <rect x="117.188" y="52.246" width="11.424" height="48.256" />
                                        <path d="M180.335,58.174c-1.784-2.211-4.04-3.938-6.705-5.133c-2.662-1.195-5.722-1.801-9.094-1.801
			c-2.27,0-4.367,0.28-6.233,0.832c-1.744,0.513-3.255,1.09-4.499,1.715V29.039l-11.424,1.889v68.064l0.12,0.038
			c2.185,0.669,4.981,1.287,8.311,1.836c3.315,0.548,6.854,0.827,10.518,0.827c3.789,0,7.231-0.59,10.231-1.753
			c3-1.162,5.594-2.87,7.712-5.079c2.112-2.204,3.757-4.89,4.893-7.984c1.132-3.092,1.704-6.61,1.704-10.458
			c0-3.722-0.479-7.176-1.427-10.264C183.49,63.061,182.108,60.376,180.335,58.174z M174.169,76.238
			c0,4.821-1.158,8.652-3.444,11.387c-2.275,2.726-5.467,4.108-9.486,4.108c-1.758,0-3.277-0.077-4.515-0.229
			c-1.16-0.141-2.142-0.299-2.92-0.47V63.685c0.951-0.638,2.222-1.231,3.778-1.761c1.596-0.541,3.318-0.815,5.123-0.815
			c3.949,0,6.876,1.362,8.698,4.048C173.238,67.864,174.169,71.592,174.169,76.238z" />
                                        <path d="M216.753,51.056c-2.813,0-5.609,0.543-8.308,1.615c-2.701,1.075-5.125,2.691-7.204,4.802
			c-2.082,2.11-3.774,4.795-5.029,7.981c-1.254,3.182-1.89,6.932-1.89,11.148c0,3.603,0.496,6.965,1.474,9.993
			c0.978,3.032,2.501,5.704,4.524,7.941c2.026,2.241,4.637,4.013,7.759,5.266c3.12,1.254,6.841,1.89,11.057,1.89
			c1.643,0,3.282-0.093,4.876-0.275c1.594-0.184,3.093-0.416,4.455-0.689c1.389-0.279,2.629-0.588,3.684-0.921
			c1.07-0.336,1.926-0.663,2.546-0.972l0.112-0.056l-1.522-9.409l-0.2,0.086c-1.264,0.541-3.026,1.062-5.236,1.547
			c-2.205,0.483-4.674,0.729-7.338,0.729c-4.517,0-8.009-1.049-10.378-3.119c-2.329-2.032-3.674-4.936-4.005-8.632h31.577
			l0.015-0.154c0.058-0.599,0.105-1.296,0.137-2.072c0.032-0.8,0.047-1.477,0.047-2.068c0-8.125-1.88-14.328-5.587-18.436
			C228.605,53.14,223.368,51.056,216.753,51.056z M206.23,71.486c0.189-1.329,0.514-2.637,0.973-3.894
			c0.477-1.315,1.16-2.501,2.03-3.521c0.868-1.017,1.945-1.85,3.2-2.477c1.252-0.626,2.736-0.943,4.412-0.943
			c1.612,0,3.021,0.286,4.185,0.852c1.163,0.567,2.162,1.353,2.968,2.337c0.806,0.988,1.427,2.171,1.845,3.516
			c0.404,1.299,0.618,2.688,0.633,4.13H206.23z" />
                                        <path d="M267.712,91.916c-1.444-0.179-2.649-0.438-3.584-0.77c-0.911-0.323-1.643-0.799-2.17-1.416
			c-0.529-0.615-0.901-1.377-1.108-2.265c-0.21-0.892-0.317-2.019-0.317-3.349V29.039l-11.428,1.889v55.295
			c0,2.385,0.264,4.543,0.785,6.412c0.526,1.885,1.451,3.482,2.748,4.748c1.295,1.266,3.06,2.251,5.246,2.928
			c2.175,0.673,4.949,1.046,8.246,1.108l0.147,0.003l1.612-9.484L267.712,91.916z" />
                                    </g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                                <g>
                                </g>
                            </svg>
                        </h1>
                    </div>
                    <div className='col-sm-8'>
                        <ul className='topNav'>
                           <li><a href='https://www.tribel.com'>Home</a></li> 
                           <li><Link to="/">Terms Of Service</Link></li> 
                           <li><Link to="/privacy">Privacy and Terms</Link> </li>
                           <li><Link to="/Contact">contact</Link> </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </header>

    )
}

const Hello = () => {
    return (
        <div>HellO</div>
    )
}

export default Header