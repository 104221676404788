import React from 'react'

export const Terms = () => {
    return (
        <section className='terms_Privacy_Page'>
            <div className="portfolio_body trems_body">
                <div className="container custom-container">
                    <div className="main">
                        <div className="privacy_area main_topic_margin">
                            <div className="privacy_area_top">
                                <div className="row">
                                    <h1 className="col-md-8">Terms of Service</h1>
                                    <div className="col-md-4 text-right color-3 ">
                                        <b>Updated at 2020-11-13</b>
                                    </div>
                                </div>
                            </div>
                            <div className="privacy_area_body_area">
                                <div className="privacy_area_body">
                                    <div className="row privacy-content-dis">
                                        <div className="col-md-12">
                                            <p>
                                                These Terms of Use (this
                                                <b> "Agreement"</b>
                                                ), which is a legal agreement between you (
                                                <b>"You" </b>
                                                or
                                                <b> "you"</b>
                                                ) and Tribel, a Florida Limited Liability Company{" "}
                                                <br />(<b>"Tribel" or “We”</b>
                                                ).This Agreement shall govern your use of and access
                                                to the Website (as defined below).
                                            </p>

                                            <p className="pt-3">
                                                By checking any acceptance boxes, clicking any
                                                acceptance buttons, submitting any text or content or
                                                simply by making any use of the Website, you
                                                <ul className="first-list pt-2">
                                                    <li>
                                                        Accept this Agreement and agree to be bound by
                                                        each of its terms, and
                                                    </li>
                                                    <li>Represent and warrant to Tribel that</li>
                                                </ul>
                                                <span className="indent-10">
                                                    <ul className="first-list">
                                                        <li>
                                                            You are at least 13 years of age and have the
                                                            authority to enter into this Agreement (either
                                                            on your own behalf or by having a parent or
                                                            legal guardian agree to the terms set forth
                                                            herein on your behalf)
                                                        </li>
                                                        <li>
                                                            This Agreement is binding and enforceable
                                                            against you,
                                                        </li>
                                                        <li>
                                                            to the extent an individual is accepting this
                                                            Agreement on behalf of an entity, such an
                                                            individual has the right and authority to agree
                                                            to all of the terms set forth herein on behalf
                                                            of such entity. Tribel may amend this Agreement
                                                            from time to time, each of which amendments
                                                            shall be deemed to be effective immediately
                                                            after posting the updated Agreement at the
                                                            Website. You should check the Website and this
                                                            Agreement regularly to ensure you remain current
                                                            on and understand fully the terms and agreements
                                                            set forth herein.
                                                        </li>
                                                    </ul>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-40">
                                        <div className="col-md-4">
                                            <ul className="privacy-links left-links">
                                                <li>
                                                    <a
                                                        role="button"
                                                        href="#collapseOne"
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne"
                                                    >
                                                        Purpose
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseTwo"
                                                        aria-expanded="false"
                                                        aria-controls="collapseTwo"
                                                    >
                                                        certain Restrictionss
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseThree"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree"
                                                    >
                                                        Certain Responsibilities
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapsefour"
                                                        aria-expanded="false"
                                                        aria-controls="collapsefour"
                                                    >
                                                        Tribel Rights
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapsefive"
                                                        aria-expanded="false"
                                                        aria-controls="collapsefive"
                                                    >
                                                        Fees
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapsesix"
                                                        aria-expanded="false"
                                                        aria-controls="collapsesix"
                                                    >
                                                        Terms and Termination
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseseven"
                                                        aria-expanded="false"
                                                        aria-controls="collapseseven"
                                                    >
                                                        Intellectual Property
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseeight"
                                                        aria-expanded="false"
                                                        aria-controls="collapseeight"
                                                    >
                                                        Confidentiality; Non-Solicitation
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapsenine"
                                                        aria-expanded="false"
                                                        aria-controls="collapsenine"
                                                    >
                                                        Disclaimers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseten"
                                                        aria-expanded="false"
                                                        aria-controls="collapseten"
                                                    >
                                                        Limitation on Liability
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapseeleven"
                                                        aria-expanded="false"
                                                        aria-controls="collapseeleven"
                                                    >
                                                        Force Majeure
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse12"
                                                        aria-expanded="false"
                                                        aria-controls="collapse12"
                                                    >
                                                        General Terms
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse13"
                                                        aria-expanded="false"
                                                        aria-controls="collapse13"
                                                    >
                                                        Copyright Infringement
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse14"
                                                        aria-expanded="false"
                                                        aria-controls="collapse14"
                                                    >
                                                        Governing Law and Venue
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse15"
                                                        aria-expanded="false"
                                                        aria-controls="collapse15"
                                                    >
                                                        Business Transfer
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse16"
                                                        aria-expanded="false"
                                                        aria-controls="collapse16"
                                                    >
                                                        Acceptance
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse17"
                                                        aria-expanded="false"
                                                        aria-controls="collapse17"
                                                    >
                                                        Links
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="collapsed"
                                                        href="#collapse18"
                                                        aria-expanded="false"
                                                        aria-controls="collapse18"
                                                    >
                                                        Privacy
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="text-section" id="collapseOne">
                                                <label>Purpose</label>
                                                <p>
                                                    The purpose of this Agreement is to set forth the
                                                    terms and conditions under which, among other things
                                                    Tribel will license to you use of certain of
                                                    Tribel's technology, software and/or services such
                                                    that you can utilize the Website (collectively, the
                                                    "Purpose"). As stipulated elsewhere in this
                                                    Agreement, Tribel does not exert any control over
                                                    any merchants, retailers, commercial ventures or
                                                    other third parties, and as such is not liable or
                                                    responsible for any actions taken or omitted to be
                                                    taken by any such third party or user. You agree to
                                                    release Tribel of any potential claims against
                                                    Tribel for the use of Tribel’s technology, software
                                                    and/or services.
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapseTwo">
                                                <label>certain Restrictions</label>
                                                <p>
                                                    You shall not directly or indirectly copy or
                                                    reproduce all or any part of the Website, whether
                                                    electronically, mechanically or otherwise, in any
                                                    form including, but not limited to, the copying of
                                                    presentation, style or organization. You shall use
                                                    the Website solely for its intended purposes and
                                                    shall not use the Website for the benefit of any
                                                    third party except as specifically contemplated
                                                    under this Agreement. You shall not use the Website
                                                    to post, transmit, convey, submit, distribute, store
                                                    or destroy any content, text, photographs,
                                                    descriptions, drawings, content, audio materials,
                                                    messages or other information (collectively, "Posted
                                                    Information"):
                                                    <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            in violation of any applicable law, statute,
                                                            ordinance or regulation;
                                                        </li>
                                                        <li>
                                                            in a manner that will infringe the intellectual
                                                            property rights of others;
                                                        </li>
                                                        <li>
                                                            that is defamatory, obscene or trade libelous;
                                                        </li>
                                                        <li>
                                                            that contains any viruses, Trojan horses, worms,
                                                            time bombs, cancel bots or other computer
                                                            programming routines that are intended to
                                                            damage, detrimentally interfere with,
                                                            surreptitiously intercept or expropriate any
                                                            system, data or personal information;
                                                        </li>
                                                        <li>
                                                            in violation of the any acceptable use policy or
                                                            other policy posted at the Website from time to
                                                            time. You shall not violate or attempt to
                                                            violate the security of the Website. You shall
                                                            not reverse engineer, decompile, disassemble or
                                                            otherwise attempt to derive source code or other
                                                            trade secrets from the Website, including,
                                                            without limitation, any of the software
                                                            comprising or in any way making up a part of the
                                                            Website. In addition, you will not export,
                                                            re-export or permit any third party to export or
                                                            re-export, directly or indirectly, the Website
                                                            where such export or re-export is prohibited by
                                                            applicable law without appropriate licenses and
                                                            clearances. You shall defend and indemnify
                                                            Tribel, at your sole cost and expense, from and
                                                            against any claims, damages, liabilities and/or
                                                            expenses arising out of Your breach of any of
                                                            Your obligations or representations set forth in
                                                            this Section 3.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapseThree">
                                                <label>Certain Responsibilities</label>
                                                <p>
                                                    You shall be solely responsible for:
                                                    <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            all Posted Information you input into the
                                                            Website;
                                                        </li>
                                                        <li>
                                                            ensuring that all Posted Information is
                                                            appropriate in tone and not intended to harm
                                                            others;
                                                        </li>
                                                        <li>
                                                            complying with all applicable laws, rules and
                                                            regulations at all times; and
                                                        </li>
                                                        <li>
                                                            maintaining all passwords and access codes to
                                                            the Website, and refraining from sharing or
                                                            otherwise permitting third parties to use any
                                                            such passwords and/or access codes to access the
                                                            Website.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapsefour">
                                                <label>Tribel Rights</label>
                                                <p>
                                                    Tribel shall be entitled, at its sole discretion, to
                                                    suspend, restrict and/or terminate, without notice
                                                    of any kind, your access to the Website or your
                                                    Tribel account for any reason at Tribel’s own
                                                    discretion. Notwithstanding the foregoing, Tribel
                                                    shall not be required to review or monitor any
                                                    Posted Information entered into the Website or
                                                    otherwise submitted by you, and you shall be solely
                                                    responsible for the veracity and accuracy of all
                                                    such data, content, and information.
                                                    <br />
                                                    <br />
                                                    There will be no tolerance for abusive content nor
                                                    abusive users. There will be no tolerance for
                                                    misinformation related to the Covid-19 pandemic,
                                                    whether in posts or comments. Any violators will be
                                                    banned temporarily or permanently from Tribel, at
                                                    our discretion. We will also delete the offending
                                                    content from the network.
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapsefive">
                                                <label>Fees</label>
                                                <p>
                                                    Tribel does not charge its users to access and use
                                                    the Website. If this policy changes Tribel will
                                                    provide 30 days advance notice to you and/or
                                                    potential customers.
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapsesix">
                                                <label>Terms and Termination</label>
                                                <p>
                                                    This Agreement shall continue in full force until
                                                    the earlier to occur of:
                                                    <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            either party providing written or electronic
                                                            notice of termination to the other party (at
                                                            which point You shall no longer be entitled to
                                                            access or use the Website).
                                                        </li>
                                                        <li>
                                                            Tribel electing to terminate your access to the
                                                            Website, with or without notice (the "Term" ) at
                                                            its own discretion. For purposes of clarity, you
                                                            are entitled to terminate your Tribel account at
                                                            any time. Upon termination of this Agreement for
                                                            any reason, you shall no longer be entitled to
                                                            access, use or transfer the Website or any other
                                                            non-public portions of the Website. <br />
                                                            <br />
                                                            In addition to the foregoing, in the event that
                                                            Tribel determines, in its sole and absolute
                                                            discretion, that you have breached this
                                                            Agreement, threatened to breach this Agreement,
                                                            committed any fraud or deception, breached any
                                                            Tribel policy in effect from time to time or
                                                            otherwise failed to perform to the standards
                                                            required of Tribel, Tribel shall be entitled, at
                                                            its discretion and in addition to any other
                                                            remedies it may have hereunder and/or at law, to
                                                            terminate, cancel or suspend Your access to the
                                                            Website, in each of the foregoing cases at any
                                                            time and for any period of time.
                                                            <br />
                                                            <br /> Tribel shall not be responsible for the
                                                            return of any Posted Information of any kind to
                                                            you upon any termination of this Agreement or
                                                            suspension of your access to the Website,
                                                            including without limitation any information
                                                            input into the Website by you. Sections 3, 4, 5,
                                                            6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 and
                                                            18 of this Agreement shall survive any
                                                            termination of this Agreement.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapseseven">
                                                <label>Intellectual Property</label>
                                                <p>
                                                    <strong>General Ownership</strong>
                                                    <br />
                                                    <br />
                                                    All trademarks, patents, copyrights and other
                                                    intellectual property rights owned by either party
                                                    on the date hereof shall continue to be owned solely
                                                    by such party, and except as set forth herein,
                                                    nothing in this Agreement shall be deemed to confer
                                                    any rights to any such intellectual property on the
                                                    other party. For purposes of clarity:
                                                    <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            as between you and Tribel, you shall be deemed
                                                            to be the sole owner of all Posted Information
                                                            entered into the Website or otherwise posted by
                                                            you.
                                                        </li>
                                                        <li>
                                                            Tribel is the sole owner of the name "Tribel" as
                                                            well as the Website and all source code, object
                                                            code, software, content, copyrights, trademarks,
                                                            patents and other intellectual property related
                                                            thereto or included therein. All suggestions,
                                                            recommendations, bug-fixes, error-fixes or other
                                                            communications from you to Tribel regarding the
                                                            Website shall, upon submission to Tribel, be
                                                            owned solely and exclusively by Tribel. In
                                                            addition, Tribel shall be entitled to post
                                                            feedback at the Website (and/or allows others to
                                                            do so), both positive and negative, regarding
                                                            any user. You acknowledge and agree that the
                                                            applicable supplier(s) of any third party
                                                            software included within the Website shall own
                                                            all worldwide rights, title, and interest in and
                                                            to such third party software (and any
                                                            intellectual property rights therein), subject
                                                            to such suppliers' license, if any, of such
                                                            third party software to Tribel.
                                                        </li>
                                                    </ul>
                                                    <strong>
                                                        <br />
                                                        <br />
                                                        Use of Posted Information
                                                    </strong>
                                                    <br />
                                                    <br />
                                                    In exchange for your use of Tribel, you irrevocably waive and cause to be waived, against Tribel and its users any claims and assertions of moral rights or attribution with respect to your Posted Information. Tribel shall be entitled to display advertising, and/or any other content at locations of its choosing within the website including without limitation adjacent to your posted Information.
                                                </p>
                                            </div>
                                            <div className="text-section" id="collapseeight">
                                                <label>Confidentiality; Non-Solicitation</label>
                                                <p>
                                                    You agree to treat as confidential all confidential
                                                    information of Tribel, not to use such confidential
                                                    information for any purpose other than to the
                                                    limited extent necessary to use the Website and not
                                                    to disclose such confidential information to any
                                                    third party except as may be reasonably required
                                                    pursuant to this Agreement and subject to
                                                    confidentiality obligations at least as protective
                                                    as those set forth herein. Without limiting the
                                                    generality of the foregoing, You shall use at least
                                                    the same degree of care which You use to prevent the
                                                    disclosure of Your own confidential information of
                                                    like importance to prevent the disclosure of
                                                    confidential information disclosed by Tribel,
                                                    provided however , that in no event shall such
                                                    degree of care be less than reasonable in light of
                                                    general industry practice. In addition, during the
                                                    Term and for a period of one year thereafter, you
                                                    shall refrain from directly or indirectly
                                                    soliciting, enticing, persuading or inducing any
                                                    individual who is then, or has been within the
                                                    1-year period prior to the applicable date, an
                                                    employee of Tribel to terminate employment with
                                                    Tribel.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapsenine">
                                                <label>Disclaimers</label>
                                                <p>
                                                    <strong>No Warranties</strong>
                                                    <br />
                                                    <br />
                                                    Except as explicitly set forth herein, neither
                                                    Tribel, its affiliates or any of any such party's
                                                    equity holders, directors, officers, employees,
                                                    agents, suppliers, licensors nor the like, makes any
                                                    warranties of any kind, either express or implied,
                                                    including, without limitation, <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            warranties of merchantability or fitness for a
                                                            particular purpose,
                                                        </li>
                                                        <li>that the Website will be error-free,</li>
                                                        <li>
                                                            as to a minimum level of uptime for the Website,
                                                            or
                                                        </li>
                                                        <li>
                                                            as to the results that may be obtained by you by
                                                            entering into this Agreement and/or using the
                                                            Website. You agree and acknowledge that the
                                                            Website is licensed and/or provided hereunder on
                                                            an "as is" basis. In addition, you hereby agree
                                                            and acknowledge that:
                                                        </li>
                                                    </ul>
                                                    <span className="indent-10">
                                                        <ul className="first-list">
                                                            <li>
                                                                Tribel shall not be responsible for any
                                                                actions taken by any other party using the
                                                                Website or reviewing any of your Posted
                                                                Information;
                                                            </li>
                                                            <li>
                                                                Tribel does not recommend or endorse any third
                                                                parties hereunder, and makes no
                                                                representations or warranties whatsoever
                                                                regarding any such third party;
                                                            </li>
                                                            <li>
                                                                Tribel is not a party to any transaction
                                                                between you and any store, retailer or
                                                                business with which Tribel has a business
                                                                relationship, and as such, any disputes
                                                                regarding purchases, rewards and/or any other
                                                                aspect of any transaction or other commercial
                                                                dealings are solely between you and such third
                                                                party;
                                                            </li>
                                                            <li>
                                                                Tribel is not responsible for any other
                                                                party's compliance with applicable laws, rules
                                                                or regulations;
                                                            </li>
                                                            <li>
                                                                Tribel's services are administrative in nature
                                                                and Tribel is not responsible for ensuring
                                                                that any third party honor any reward, loyalty
                                                                or other obligations such third party may have
                                                                towards you;
                                                            </li>
                                                            <li>
                                                                Tribel shall not, under any set of
                                                                circumstances, be responsible or liable for
                                                                any content, text, photographs and/or other
                                                                Posted Information, including any Posted
                                                                Information which may violate applicable law
                                                                and/or a third party's intellectual property
                                                                rights; and
                                                            </li>
                                                            <li>
                                                                the user understandsthe Website may not
                                                                function properly or as intended at times and
                                                                Tribel is not responsible or liable to You
                                                                and/or any third parties in case Tribel
                                                                technology malfunctions or does not work
                                                                properly. However, Tribel will make its best
                                                                effort to ensure the technology is up to date
                                                                and functions properly.
                                                            </li>
                                                        </ul>
                                                    </span>
                                                    <strong>
                                                        <br />
                                                        <br />
                                                        Unavailability of Website
                                                    </strong>
                                                    <br />
                                                    <br />
                                                    You are responsible, at your sole cost and expense,
                                                    for providing all equipment necessary to access the
                                                    Internet, the Website. While it is Tribel's
                                                    objective to make the Website accessible at all
                                                    times, the Website may be unavailable from time to
                                                    time for any reason including, without limitation,
                                                    routine maintenance. In addition, various portions
                                                    of the Website may operate slowly from time to time.{" "}
                                                    <br />
                                                    <br />
                                                    You understand and acknowledge that due to
                                                    circumstances both within and outside of the control
                                                    of Tribel, access to the Website may be interrupted,
                                                    suspended or terminated from time to time. In
                                                    particular, and not in limitation of the foregoing,
                                                    Tribel shall not be liable in any way for any delay
                                                    in responding to an inquiry or question forwarded by
                                                    you or third parties or the effects any delay or
                                                    unavailability may have on You or third parties. You
                                                    agree that Tribel shall not be liable for any
                                                    damages arising from any such interruption,
                                                    suspension or termination of the website and that
                                                    you shall put in place contingency plans to account
                                                    for. Such periodic interruptions or suspensions of
                                                    the website.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapseten">
                                                <label>Limitation on Liability</label>
                                                <p>
                                                    Tribel shall not, under any set of circumstances, be
                                                    liable to you for any special, incidental, indirect,
                                                    punitive or consequential damages, including, but
                                                    not limited to, lost profits or data arising out of
                                                    this agreement or your use of the website, whether
                                                    based in contract, tort or any other legal theory,
                                                    even if advised of the possibility of such damages.
                                                    In any event, Tribel's total liability to you for
                                                    any claim arising hereunder or related hereto shall
                                                    not exceed $50. You agree to release Tribel from all
                                                    claims you may have against Tribel for the use of
                                                    Tribel technology or any of its products or
                                                    services.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapseeleven">
                                                <label>Force Majeure</label>
                                                <p>
                                                    Additionally, Tribel shall not be liable to you for
                                                    failure or delay in performing any obligations
                                                    hereunder even if such failure or delay is due to
                                                    circumstances withinTribel’s control and/or beyond
                                                    its reasonable control.
                                                    <br />
                                                    <br />
                                                    Tribel uses extensive and sophisticated secure
                                                    technology to protect your data and transmissions
                                                    between you and Tribel. Transmission between
                                                    browsers and our web server is implemented using
                                                    secure technologies. Although Tribel has taken these
                                                    reasonable and appropriate measures to ensure that
                                                    your personal information is delivered and disclosed
                                                    only in accordance with your instructions, Tribel
                                                    cannot and does not guarantee and cannot control
                                                    that the personal information you provide will not
                                                    be intercepted by others and decrypted.
                                                    <br />
                                                    <br />
                                                    Tribel shall not be responsible for any failure to
                                                    perform due to unforeseen circumstances or to causes
                                                    beyond Tribel’s reasonable control, including but
                                                    not limited to acts of God, war, riot, embargoes,
                                                    acts of civil or military authority, fire, floods,
                                                    accidents, strikes, acts or omissions of carriers,
                                                    transmitters, providers, or acts of vandals, or
                                                    hackers.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse12">
                                                <label>General Terms</label>
                                                <p>
                                                    <strong>
                                                        The following terms and conditions govern general
                                                        use of the Website and Tribel Technology, Products
                                                        and Services:
                                                    </strong>
                                                    <br />
                                                    <br />
                                                    You agree to abide by all restrictions displayed on
                                                    the Website, as and when they are updated from time
                                                    to time, including, without limitation, the rules in
                                                    this Section 13. Tribel reserves the right to remove
                                                    any content you post to the Website, block the
                                                    sending of any inquiry or other content Tribel deems
                                                    inappropriate in its sole discretion and may
                                                    terminate all access to the Website at any time in
                                                    its sole discretion for any or no reason at all.
                                                    While Tribel reserves the right to monitor all
                                                    postings and/or content posted on the Website, it
                                                    has no obligation to do so and does not need to
                                                    notify you.
                                                    <br />
                                                    <br />
                                                    <strong>Permitted Uses.</strong> <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may use the Website only in good faith for the
                                                        purposes described herein. You may download and
                                                        print out portions of the content from the Website{" "}
                                                        <strong>only for non-commercial purposes</strong>{" "}
                                                        provided that you follow the rules in this
                                                        Agreement. You may not use the Website, or any
                                                        business listings, contact information or other
                                                        content, to promote another business or commercial
                                                        venture.
                                                    </span>
                                                    <br />
                                                    <strong>Distribution. </strong>
                                                    <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        Except as expressly permitted under other
                                                        provisions of this Agreement, You may not modify,
                                                        reproduce, duplicate, copy, photocopy, print,
                                                        republish, display, translate, transmit,
                                                        distribute, sell, resell, rent, lease, loan,
                                                        exploit, reduce to any electronic medium or
                                                        machine-readable form, or otherwise make available
                                                        in any form or by any means all or any portion of
                                                        the Website, the content or any information or
                                                        materials retrieved from either of them,
                                                        including, without limitation, graphics and logos,
                                                        in whole or in part, for any purpose.
                                                    </span>
                                                    <br />
                                                    <strong>Source Code.</strong> <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You will not modify, create derivative works of,
                                                        decompile, or otherwise attempt to extract source
                                                        code from Tribel.
                                                    </span>
                                                    <br />
                                                    <strong>Proprietary Notices.</strong> <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may not remove, change or obscure any of
                                                        Tribel technology, products or services from the
                                                        system. You must retain all copies of the content
                                                        you download and/or any copyright notice or other
                                                        proprietary notice, Privacy Policy, legal
                                                        disclosures or terms of use contained in the
                                                        content or on the Website.
                                                    </span>
                                                    <br />
                                                    <strong>Infringement.</strong> <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may not use the Website, the Website content
                                                        or any other materials from the Website in any
                                                        manner that may infringe upon any copyright or
                                                        other intellectual property rights, proprietary
                                                        right, or property right of Tribel or any third
                                                        party.
                                                    </span>
                                                    <br />
                                                    <strong>Information Distribution.</strong> <br />
                                                    <br />
                                                    <strong>You may not use:</strong> <br />
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            the Website or any communications service, chat
                                                            room, message board, blog, forum, newsgroup, or
                                                            other interactive service that may be available
                                                            to you on or through the Website to transmit,
                                                            upload, post, distribute or facilitate
                                                            distribution of, or otherwise make available any
                                                            information or content, including text,
                                                            communications, software, images, sounds, data
                                                            or other information that contains explicit or
                                                            graphic descriptions or accounts of sexual acts,
                                                            including, without limitation, sexual language
                                                            of a violent orthreatening nature directed at
                                                            another individual or group of individuals;
                                                        </li>
                                                        <li>
                                                            the Website or any communications service, chat
                                                            room, message board, blog, forum, newsgroup, or
                                                            other interactive service that may be available
                                                            to you on or through the Website to transmit,
                                                            upload, post, distribute or facilitate
                                                            distribution of, or otherwise make available any
                                                            information or content, including text,
                                                            communications, software, images, sounds, data
                                                            or other information that includes any inside
                                                            information and/or proprietary or confidential
                                                            information learned or disclosed under
                                                            nondisclosure agreements;
                                                        </li>
                                                        <li>
                                                            the Website or any communications service, chat
                                                            room, message board, blog, forum, newsgroup, or
                                                            other interactive service that may be available
                                                            to you on or through the Website to transmit,
                                                            upload, post, distribute or facilitate
                                                            distribution of, or otherwise make available any
                                                            information or content, including text,
                                                            communications, software, images, sounds, data
                                                            or other information thatyou are restricted from
                                                            using under any law, statute, ordinance or
                                                            regulation;
                                                        </li>
                                                        <li>
                                                            the Website or any communications service, chat
                                                            room, message board, blog, forum, newsgroup, or
                                                            other interactive service that may be available
                                                            to you on or through the Website to transmit,
                                                            upload, post, distribute or facilitate
                                                            distribution of, or otherwise make available any
                                                            information or content, including text,
                                                            communications, software, images, sounds, data
                                                            or other information thatinfringes upon the
                                                            intellectual property rights of any third party;
                                                            or
                                                        </li>
                                                        <li>
                                                            the Website and any communications service, chat
                                                            room, message board, blog, forum, newsgroup, or
                                                            other interactive service that may be available
                                                            to you on or through the Website to transmit,
                                                            upload, post, distribute or facilitate
                                                            distribution of, or otherwise make available any
                                                            information or content, including text,
                                                            communications, software, images, sounds, data
                                                            or other information that contains software
                                                            viruses or any other computer code, files or
                                                            programs that are designed or intended to
                                                            disrupt, damage, or limit the functions of any
                                                            software, hardware, or telecommunications
                                                            equipment or to damage or obtain unauthorized
                                                            access to any data or other information of any
                                                            other party.
                                                        </li>
                                                    </ul>
                                                    <br />
                                                    <br />
                                                    <strong>Other Prohibited Uses. </strong>
                                                    <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may not use the Website for any purpose
                                                        that:is unlawful, threatening, abusive, harassing,
                                                        defamatory, libelous, deceptive, fraudulent,
                                                        invasive of another's privacy, tortious, or
                                                        otherwise violates Tribel's rules or
                                                        policies;victimizes, harasses, degrades, or
                                                        intimidates an individual or group of individuals
                                                        on the basis of religion, gender, sexual
                                                        orientation, race, ethnicity, age, disability, or
                                                        any other classification protected by law;invades
                                                        any person's or entity's privacy or other
                                                        rights;constitutes unauthorized or unsolicited
                                                        advertising, junk or bulk e-mail (also known as
                                                        "spamming"), chain letters, any other form of
                                                        unauthorized solicitation, or any form of lottery
                                                        or gambling;misidentifies you or impersonates any
                                                        person or entity, including, without limitation,
                                                        any employee or representative of Tribel, or
                                                        falsely states, implies, or otherwise
                                                        misrepresents Your affiliation with a person or
                                                        entity by, for example, pretending to be someone
                                                        other than you or pretending to represent a
                                                        company or organization that you are not
                                                        affiliated with or authorized to represent; or
                                                        could otherwise reasonably be deemed or viewed to
                                                        be unethical, illegal or offensive.
                                                    </span>
                                                    <br />
                                                    <strong>Harm to Minors. </strong>
                                                    <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may not take any action on the Website or use
                                                        the Website content to harm minors in any way.
                                                    </span>
                                                    <br />
                                                    <strong>Solicitation. </strong>
                                                    <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        You may not use the Website or any Website content
                                                        in a manner that violates any state or federal law
                                                        regulating commercial e-mail, facsimile
                                                        transmissions or telephone solicitations.
                                                    </span>
                                                    <br />
                                                    <ul className="first-list">
                                                        <li>
                                                            The Website, and the Website content may contain
                                                            and/or provide access to content provided by
                                                            third parties, including, without limitation,
                                                            information, dialogue, opinions, stories,
                                                            advice, statistical data, text, software, music,
                                                            sound, photographs, graphics, video, messages,
                                                            and other materials, whether publicly or
                                                            privately posted to or e-mailed or otherwise
                                                            transmitted through the Website ( "Third Party
                                                            Content" ) that may include content you find to
                                                            be offensive, indecent or objectionable.
                                                            <br />
                                                            <br /> The third party from whom or which any
                                                            such Third Party Content originates is solely
                                                            responsible for it and Tribel assumes no
                                                            responsibility to verify, has no control or
                                                            influence over, makes no representations
                                                            regarding, and does not guarantee the accuracy,
                                                            integrity or quality of any Third Party Content.
                                                            Accordingly, Tribel has no liability of any kind
                                                            to you or any other person relating to any Third
                                                            Party Content, including, without limitation,
                                                            mistake, misstatement of law, omission,
                                                            falsehood, defamation, obscenity, pornography,
                                                            profanity, opinion, representation and any other
                                                            content contained in the Third Party Content or
                                                            for any loss or damage of any kind incurred as a
                                                            result of the use of any Third Party Content.
                                                            Statements of opinion and commentary in Third
                                                            Party Content are those of the third party and,
                                                            unless Tribel expressly states in writing to the
                                                            contrary, Tribel neither endorses nor adopts as
                                                            its belief any such statements. Tribel may
                                                            provide information in articles Tribel posts or
                                                            links to through the Website only for
                                                            educational and general informational purposes
                                                            and not as professional advice. Tribel has made
                                                            no attempt to verify any information contained
                                                            in any such articles.
                                                        </li>
                                                        <li>
                                                            As a convenience to you and other Website
                                                            visitors, the Website may contain links to
                                                            websites that are owned and operated by third
                                                            parties that are not affiliated with Tribel.
                                                            When you use these links, you will leave the
                                                            Website and Tribel will have no ability to
                                                            protect your interests and you release Tribel
                                                            from any potential claim against Tribel. You
                                                            visit linked websites at your own risk and it is
                                                            your responsibility to take any protective
                                                            measures to guard against viruses and other
                                                            destructive elements. Tribel is not responsible
                                                            for and, unless it expressly states otherwise in
                                                            writing, makes no warranty or representation
                                                            regarding and does not endorse any external
                                                            website on or through a linked website.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse13">
                                                <label>Copyright Infringement</label>
                                                <p>
                                                    Tribel respects the intellectual property of others
                                                    and asks you and all users to do the same. Material
                                                    available on or through other websites may be
                                                    protected by copyright and the intellectual property
                                                    laws of the United States and/or other countries,
                                                    and the terms of use of those websites, and of this
                                                    Agreement, govern your use of that material. It is
                                                    Tribel's policy, in appropriate circumstances and at
                                                    its discretion, to disable and/or terminate the
                                                    accounts of users who may infringe or repeatedly
                                                    infringe the copyrights or other intellectual
                                                    property rights of Tribel and/or others.
                                                    <br />
                                                    <br />
                                                    If you believe that your intellectual property or
                                                    work has been copied in a way that constitutes
                                                    copyright infringement, or that your intellectual
                                                    property rights have been otherwise violated, please
                                                    provide Tribel's Agent for Notice with the following
                                                    information in English (your"Notice"):
                                                    <br />
                                                    <br />
                                                    In some circumstances, in order to notify the
                                                    individual or entity who or which provided the
                                                    allegedly infringing content to which Tribel has
                                                    disabled access, Tribel may forward a copy of a
                                                    valid Notice including name and email address to
                                                    such individual or entity. Tribel's Agent for Notice
                                                    of claims of copyright or other intellectual
                                                    property infringement can be reached as follows:
                                                    INFO@Tribel.COM
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse14">
                                                <label>Governing Law and Venue</label>
                                                <p>
                                                    Each party shall pay its own costs and expenses in
                                                    connection with this Agreement and its activities
                                                    hereunder. The rights and obligations of the parties
                                                    under this Agreement shall be governed by the laws
                                                    of the State of Florida, without reference to
                                                    conflict of law principles, and all disputes arising
                                                    hereunder or in connection with this Agreement, the
                                                    Website shall be resolved in the appropriate Federal
                                                    or state court located solely and exclusively in
                                                    Miami, Florida. You hereby consent to exclusive
                                                    jurisdiction and personal jurisdiction in Miami,
                                                    Florida and agree not to raise any defense of forum
                                                    non-conveniens or any similar defense.
                                                    <br />
                                                    <br /> The relationship between the parties under
                                                    this Agreement is that of independent contractors
                                                    and neither shall be, nor represent itself to be, a
                                                    joint venture, franchisor, franchisee, partner,
                                                    agent or representative of the other party for any
                                                    purpose whatsoever. This Agreement shall inure to
                                                    the benefit of and be binding upon the parties
                                                    hereto and their respective successors and assigns,
                                                    but shall not be assignable by you without Tribel's
                                                    prior written consent. If any provision of this
                                                    Agreement is held to be unenforceable or invalid for
                                                    any reason, or if any governmental agency rules that
                                                    any portion of this Agreement is illegal or contrary
                                                    to public policy, the remaining provisions, to the
                                                    extent feasible, will continue in full force and
                                                    effect with such unenforceable or invalid provision
                                                    to be changed and interpreted to best accomplish its
                                                    original intent and objectives.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse15">
                                                <label>certain Restrictions</label>
                                                <p>
                                                    Each party shall pay its own costs and expenses in
                                                    connection with this Agreement and its activities
                                                    hereunder. The rights and obligations of the parties
                                                    under this Agreement shall be governed by the laws
                                                    of the State of Florida, without reference to
                                                    conflict of law principles, and all disputes arising
                                                    hereunder or in connection with this Agreement, the
                                                    Website shall be resolved in the appropriate Federal
                                                    or state court located solely and exclusively in
                                                    Miami, Florida. You hereby consent to exclusive
                                                    jurisdiction and personal jurisdiction in Miami,
                                                    Florida and agree not to raise any defense of forum
                                                    non-conveniens or any similar defense.
                                                    <br />
                                                    <br /> The relationship between the parties under
                                                    this Agreement is that of independent contractors
                                                    and neither shall be, nor represent itself to be, a
                                                    joint venture, franchisor, franchisee, partner,
                                                    agent or representative of the other party for any
                                                    purpose whatsoever. This Agreement shall inure to
                                                    the benefit of and be binding upon the parties
                                                    hereto and their respective successors and assigns,
                                                    but shall not be assignable by you without Tribel's
                                                    prior written consent. If any provision of this
                                                    Agreement is held to be unenforceable or invalid for
                                                    any reason, or if any governmental agency rules that
                                                    any portion of this Agreement is illegal or contrary
                                                    to public policy, the remaining provisions, to the
                                                    extent feasible, will continue in full force and
                                                    effect with such unenforceable or invalid provision
                                                    to be changed and interpreted to best accomplish its
                                                    original intent and objectives.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse16">
                                                <label>Business Transfer</label>
                                                <p>
                                                    We may, in the future, sell or otherwise transfer
                                                    some or all of our assets or equity to a third
                                                    party. Your personally identifiable information and
                                                    other information we obtain from you via the Website
                                                    may be disclosed to any potential or actual third
                                                    party purchasers of such assets or equity and may be
                                                    among the assets transferred and by you signing
                                                    and/or accepting the terms of this Agreement, you
                                                    agree and consent to such transfer.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse17">
                                                <label>Acceptance</label>
                                                <p>
                                                    If you do not agree to the terms of this agreement,
                                                    please do not provide us with any information and do
                                                    not use the Website. By using the Website and
                                                    voluntarily providing information to us, you consent
                                                    to our collection and use of the information as set
                                                    forth in this document.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapse18">
                                                <label>Links</label>
                                                <p>
                                                    The Website may contain links to other websites that
                                                    are owned and operated by third parties that are not
                                                    affiliated with us. When you use those links, you
                                                    leave the Website and we are unable to control how
                                                    the owners of the websites or applications you link
                                                    to handle any information they gather about you,
                                                    including their privacy practices and use of
                                                    cookies. This agreement applies only to the Website
                                                    and does not govern activities on linked websites or
                                                    applications. We encourage you to review the privacy
                                                    policy of any other website or application you
                                                    visit, download or access to understand its owner's
                                                    practices on how it collects, uses and discloses
                                                    your information.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapseThree">
                                                <label>certain Restrictions</label>
                                                <p>
                                                    The Website may contain links to other websites that
                                                    are owned and operated by third parties that are not
                                                    affiliated with us. When you use those links, you
                                                    leave the Website and we are unable to control how
                                                    the owners of the websites or applications you link
                                                    to handle any information they gather about you,
                                                    including their privacy practices and use of
                                                    cookies. This agreement applies only to the Website
                                                    and does not govern activities on linked websites or
                                                    applications. We encourage you to review the privacy
                                                    policy of any other website or application you
                                                    visit, download or access to understand its owner's
                                                    practices on how it collects, uses and discloses
                                                    your information.
                                                </p>
                                            </div>

                                            <div className="text-section" id="collapseThree">
                                                <label>Privacy</label>
                                                <p>
                                                    <strong>Your Privacy</strong>
                                                    <br />
                                                    <br />
                                                    <span className="indent-10">
                                                        Your Privacy. Your privacy is important to us.
                                                        Please read the Tribel Privecy policy (
                                                        <a href="https://www.tribel.com/privacy">
                                                            https://www.tribel.com/privacy
                                                        </a>
                                                        ) (the "<strong>Tribel Privacy Policy</strong>")
                                                        as it describes the types of data we collect from
                                                        you and your devices ("Data"), how we use your
                                                        Data, and the legal bases we have to process your
                                                        Data. The Privacy Statement also describes how
                                                        Tribel uses your content, which is your
                                                        communications with others; postings submitted by
                                                        you to Tribel Platform via the Services; and the
                                                        files, photos, documents, audio, digital works,
                                                        livestreams and videos that you upload, store,
                                                        broadcast or share through the Services ("
                                                        <strong>Your Content</strong>"). Where processing
                                                        is based on consent and to the extent permitted by
                                                        law, by agreeing to these Terms, you consent to
                                                        Tribel’s collection, use and disclosure of Your
                                                        Content and Data as described in the Privacy
                                                        Policy. In some cases, we will provide separate
                                                        notice and request your consent as referenced in
                                                        the Privacy Policy.
                                                    </span>
                                                    <br />
                                                    PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR
                                                    YOUR RECORDS.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
